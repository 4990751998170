import React, { useState } from 'react';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { ColumnsType } from 'antd/es/table';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { BASE_COLORS } from '@app/styles/themes/constants';
import { themeObject } from '@app/styles/themes/themeVariables';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { DashboardCard } from '@app/components/medical-dashboard/DashboardCard/DashboardCard';
import { useNavigate, useParams } from 'react-router-dom';
import * as S from './DashboardPages/DashboardPage.styles';
import * as ICS from './../components/vaults-dashboard/infoCard/InfoCard.styles';
import { activeUser } from '@app/lib/contracts';

import {
  Allocation,
  AssetBullishIndex,
  calculateProductAllocation,
  CompetitionDataPoint,
  getLatestChallengeNumber,
  ProductAllocationActions,
  ProductData,
  ProductSharesPredictors,
  TOKEN_LOGOS,
  TokenContributor,
} from '@app/lib/data';
import * as data from '@app/lib/data';
import { BaseCollapse } from '@app/components/common/BaseCollapse/BaseCollapse';
import { Collapse, InputNumber } from 'antd';
import CollapsePanel from 'antd/es/collapse/CollapsePanel';
import { BigNumber, ethers } from 'ethers';
import { DECIMALS } from '@app/lib/contracts';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { InfoCard } from '@app/components/vaults-dashboard/infoCard/InfoCard';
import { AumCard } from '@app/components/vaults-dashboard/aumCard/AumCard';
import { useResponsive } from '@app/hooks/useResponsive';
import { ArrowLeftOutlined } from '@ant-design/icons';
import ParticipantChart from '@app/pages/ParticipantChart';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseSlider } from '@app/components/common/BaseSlider/BaseSlider';
import StrengthHistoryChart from '@app/pages/StrengthHistoryChart';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
type AlignType = 'left' | 'right' | 'center';

interface ITokenViewProps {
  resetTrigger: boolean;
  assetStrengths: AssetBullishIndex[];
}

const TokenView: React.FC<ITokenViewProps> = (props) => {
  const { isTablet, isDesktop } = useResponsive();
  const { token } = useParams<{ token: string }>();
  const theme = useAppSelector((state) => state.theme.theme);
  const [participantDetails, setParticipantDetails] = React.useState<data.CompetitionData>(data.EMPTY_COMPETITION_DATA);
  const [spinning, setSpinning] = React.useState<boolean>(true);
  const [tokenContributors, setTokenContributors] = React.useState<TokenContributor[]>([]);
  const navigate = useNavigate();
  const [strengthHistory, setStrengthHistory] = React.useState<CompetitionDataPoint[]>([]);

  function greenRedDefault(input: number) {
    return (
      <span
        style={{
          color: input > 0 ? themeObject[theme]['success'] : input < 0 ? BASE_COLORS.red : 'default',
        }}
      >
        {input > 0 ? '+' + input.toFixed(2) : input.toFixed(2)}
        {}
      </span>
    );
  }

  function plusDefault(input: number) {
    return (
      <span
      // style={{
      //   color: input > 0 ? themeObject[theme]['success'] : input < 0 ? BASE_COLORS.red : 'default',
      // }}
      >
        {input > 0 ? '+' + input.toFixed(1) : input.toFixed(1)}
        {}
      </span>
    );
  }

  function getStrength(asset: string) {
    const assetStrength = props.assetStrengths.find((strength) => strength.asset.toUpperCase() === asset.toUpperCase());
    return assetStrength ? assetStrength.bullishIndex : 0;
  }

  const tokenContributorColumns: ColumnsType<TokenContributor> = [
    {
      title: 'Predictor',
      dataIndex: 'address',
      key: 'address',
      sorter: (a, b) => a.address.localeCompare(b.address),
      ellipsis: true,
    },
    {
      title: 'Ranking',
      dataIndex: 'ranking',
      key: 'ranking',
      sorter: (a, b) => a.ranking - b.ranking,
    },
    {
      title: 'Side',
      dataIndex: 'side',
      key: 'side',
      sorter: (a, b) => a.side.localeCompare(b.side),
      render: (side: string) => {
        return <span>{side.toUpperCase()}</span>;
      },
    },
  ];

  async function fetchTokenContributors() {
    const tokenContributors = await data.getTokenContributors((token as string).toUpperCase());
    setTokenContributors(tokenContributors);
  }

  async function fetchStrengthHistory() {
    const strengthHistory = await data.getStrengthHistory((token as string).toUpperCase());
    setStrengthHistory(strengthHistory);
  }

  React.useEffect(() => {
    (async () => {
      setSpinning(true);
      await fetchTokenContributors();
      await fetchStrengthHistory();
      setSpinning(false);
    })();
  }, [props.resetTrigger]);

  const cardStyle = { display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontSize: 'xx-large' };

  return (
    <>
      <PageTitle>{`Token: ${(token as string).toUpperCase()}`}</PageTitle>
      <BaseRow gutter={[10, 10]} style={{ marginTop: '1vh' }}>
        <BaseCol span={24} style={{ marginBottom: '2vh' }}>
          <BaseButton type="link" icon={<ArrowLeftOutlined />} onClick={() => navigate('/')}>
            Back to Marketplace
          </BaseButton>
        </BaseCol>
        <BaseCol span={12}>
          <BaseCard loading={spinning} title={(token as string).toUpperCase()} padding="0.1">
            <span style={cardStyle}>
              <img
                src={TOKEN_LOGOS[(token as string).toUpperCase()]}
                style={{
                  width: '3rem',
                  height: '3rem',
                  objectFit: 'contain',
                  borderRadius: '50%',
                }}
              />
            </span>
          </BaseCard>
        </BaseCol>
        <BaseCol span={12}>
          <BaseCard loading={spinning} title={`Strength Index`} padding="0.1">
            <span style={cardStyle}>{plusDefault(getStrength((token as string).toUpperCase()))}</span>
          </BaseCard>
        </BaseCol>
        {isTablet ? (
          <BaseSpin spinning={true} tip={'Coming soon!'}>
            <BaseRow gutter={[10, 10]}>
              <BaseCol span={12}>
                <BaseCard loading={spinning} padding="0.1" title={`Number of Long Predictions: `}>
                  <span style={cardStyle}>{data.getNumLongsOrShorts(tokenContributors, 'long')}</span>
                </BaseCard>
              </BaseCol>
              <BaseCol span={12}>
                <BaseCard loading={spinning} padding="0.1" title={`Number of Short Predictions: `}>
                  <span style={cardStyle}>{data.getNumLongsOrShorts(tokenContributors, 'short')}</span>
                </BaseCard>
              </BaseCol>
              <BaseCol span={24}>
                <StrengthHistoryChart
                  data={strengthHistory}
                  spinning={spinning}
                  title={`Historical Strength Index`}
                  valueName={`strength`}
                  unit={``}
                  greenRed={true}
                  chartType={`line`}
                />
              </BaseCol>
              <BaseCol span={24}>
                {tokenContributors.length > 0 ? (
                  <BaseTable
                    columns={tokenContributorColumns}
                    dataSource={tokenContributors}
                    loading={spinning}
                    pagination={false}
                    size={'small'}
                    bordered={true}
                    style={{ marginTop: '2vh' }}
                    // scroll={{ x: 'auto' }}
                  />
                ) : (
                  <p>No allocation available.</p>
                )}
              </BaseCol>
            </BaseRow>
          </BaseSpin>
        ) : (
          <BaseSpin spinning={true} tip={'Coming soon!'}>
            <BaseRow gutter={[10, 10]}>
              <BaseCol span={12}>
                <BaseCard loading={spinning} padding="0.1" title={`Number of Long Predictions: `}>
                  <span style={cardStyle}>{data.getNumLongsOrShorts(tokenContributors, 'long')}</span>
                </BaseCard>
              </BaseCol>
              <BaseCol span={12}>
                <BaseCard loading={spinning} padding="0.1" title={`Number of Short Predictions: `}>
                  <span style={cardStyle}>{data.getNumLongsOrShorts(tokenContributors, 'short')}</span>
                </BaseCard>
              </BaseCol>
            </BaseRow>
            <BaseCol span={24}>
              <StrengthHistoryChart
                data={strengthHistory}
                spinning={spinning}
                title={`Historical Strength Index`}
                valueName={`strength`}
                unit={``}
                greenRed={true}
                chartType={`line`}
              />
            </BaseCol>
            <BaseCol span={24}>
              {tokenContributors.length > 0 ? (
                <BaseTable
                  columns={tokenContributorColumns}
                  dataSource={tokenContributors}
                  loading={spinning}
                  pagination={false}
                  size={'small'}
                  bordered={true}
                  style={{ marginTop: '2vh' }}
                  // scroll={{ x: 'auto' }}
                />
              ) : (
                <p>No allocation available.</p>
              )}
            </BaseCol>
          </BaseSpin>
        )}
      </BaseRow>
    </>
  );
};

export default TokenView;
