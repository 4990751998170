import React, { useRef, useState } from 'react';
import { ConfigProvider } from 'antd';
import { HelmetProvider } from 'react-helmet-async';
import deDe from 'antd/lib/locale/de_DE';
import enUS from 'antd/lib/locale/en_US';
import GlobalStyle from './styles/GlobalStyle';
import 'typeface-montserrat';
import 'typeface-lato';
import { AppRouter } from './components/router/AppRouter';
import { useLanguage } from './hooks/useLanguage';
import { useAutoNightMode } from './hooks/useAutoNightMode';
import { usePWA } from './hooks/usePWA';
import { useThemeWatcher } from './hooks/useThemeWatcher';
import { useAppSelector } from './hooks/reduxHooks';
import { themeObject } from './styles/themes/themeVariables';

import { Chain, WagmiConfig } from 'wagmi';
import { Web3Modal } from '@web3modal/react';
import {
  ethereumClient,
  wagmiConfig,
  networkChains,
  projectId,
  VAULT_IDX,
  activeUser,
  Portfolio,
  Request,
  getPendingDeposits,
  getPendingRedeems,
  OperationsCache,
  MarketFeed,
  getMinUsdc,
  getCompletedRequests,
  CompletedRequests,
  DECIMALS,
  ACTUAL_DATA_EPOCH_MS,
  CHART_OFFSETS,
  refresh,
  getChainByHexId,
  chainMap,
} from './lib/contracts';
import * as data from './lib/data';
import * as contracts from './lib/contracts';
import { useEffect } from 'react';
import { MarketResponseObject } from '@dydxprotocol/v3-client';
import { ethers } from 'ethers';
import {
  AGGREGATED_ALLOCATIONS,
  AGGREGATED_GAINS_MM,
  AGGREGATED_REWARDS,
  AGGREGATED_STAKES,
  AggregatedAllocations,
  AggregatedData,
  AssetBullishIndex,
  CompetitionTableData,
  getAssetBullishIndex,
  getCompetitionTableData,
  getFullMarketplaceData,
  getHistoricalRelativeGains,
  getNextCompTs,
  INITIAL_SELECTED_CBS,
  UPDATE_INTERVAL_MS,
} from './lib/data';

const MIN15_IN_MS = 1000 * 60 * 15;
const MIN30_IN_MS = 1000 * 60 * 30;
const HOUR_IN_MS = 1000 * 60 * 60;
const DAY_IN_MS = 1000 * 60 * 60 * 24;
const WEEK_IN_MS = DAY_IN_MS * 7;
const MONTH_IN_MS = DAY_IN_MS * 30;

const App: React.FC = () => {
  const { language } = useLanguage();
  const theme = useAppSelector((state) => state.theme.theme);

  const [mpTableData, setMpTableData] = React.useState<Array<data.MarketplaceData>>([]);
  const [fullMpData, setFullMpData] = React.useState<Array<data.MarketplaceData>>([]);
  const [selectedProduct, setSelectedProduct] = React.useState<number>(0);
  const [productDetails, setProductDetails] = React.useState<data.ProductData>(data.EMPTY_PRODUCT_DATA);
  const [connected, setConnected] = React.useState(false);
  const [spinning, setSpinning] = React.useState(true);
  const [marketFeed, setMarketFeed] = React.useState<MarketFeed[]>([]);
  const [resetTrigger, setResetTrigger] = useState(false);
  const [cexDexSelection, setCexDexSelection] = useState('ALL');
  const [networkSelection, setNetworkSelection] = useState('ALL');
  const [connectedUser, setConnectedUser] = useState<string | undefined>(undefined);
  // const [connectedUser, setConnectedUser] = useState<activeUser | null>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const [fullCompetitionData, setFullCompetitionData] = useState<data.CompetitionData[]>([]);
  const [competitionTableData, setCompetitionTableData] = useState<data.CompetitionTableData[]>([]);
  const [compAggDataList, setCompAggDataList] = useState<data.AggregatedData[]>([
    AGGREGATED_GAINS_MM,
    AGGREGATED_REWARDS,
    AGGREGATED_STAKES,
  ]);
  const [relativeGainsData, setRelativeGainsData] = useState<data.CompetitionDataPoint[][][]>([]);
  const [aggAllocs, setAggAllocs] = useState<data.AggregatedAllocations>(AGGREGATED_ALLOCATIONS);

  const [selectedCbs, setSelectedCbs] = useState(data.INITIAL_SELECTED_CBS);
  const [assetStrengths, setAssetStrengths] = useState<AssetBullishIndex[]>([]);
  const [nextCompTs, setNextCompTs] = useState<number>(0);

  useEffect(() => {
    // const aggDataUpdate = async () => {
    //   const aggD = await data.getAggregatedData();
    //   // console.log('=====', aggD);
    //   setCompAggDataList(aggD);
    // };
    // initCompetitionData();
    data.getAggregatedData().then((aggD) => {
      setCompAggDataList(aggD);
    });
    data.getHistoricalRelativeGains().then((relGains) => {
      setRelativeGainsData(relGains);
    });
    data.getAggregatedAllocations().then((allocs) => {
      setAggAllocs(allocs);
    });
    data.getAssetBullishIndex().then((strengths) => {
      setAssetStrengths(strengths);
    });
  }, []);

  async function update() {
    setFullMpData(await data.getFullMarketplaceData());
    setCompetitionTableData(await getCompetitionTableData());
    // setMarketFeed(await contracts.getMarketFeed());
    setMarketFeed(await data.getCryptoMarketFeed());
    // setNextCompTs(0);
    const nct = await data.getNextCompTs();
    setNextCompTs(nct);
  }

  // console.log("=====", ethereumClient.getAccount().address);

  ethereumClient.watchAccount(async () => {
    setConnectedUser(ethereumClient.getAccount().address);
    // console.log("===== watchAccount", ethereumClient.getAccount().address);
  });

  useEffect(() => {
    const setupAndUpdate = async () => {
      // await contracts.setup();
      console.log(`=== initital update at time ${new Date().toISOString()} ===`);
      await update();

      // setConnected(true);
      setSpinning(false);
    };

    setupAndUpdate();

    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    intervalRef.current = setInterval(async () => {
      console.log(`===periodic update at time ${new Date().toISOString()} ===`);
      await update();
    }, UPDATE_INTERVAL_MS);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  // make updates according to changes to the following variables
  // cexDexSelection, networkSelection
  useEffect(() => {
    setMpTableData(data.getMarketplaceData(fullMpData, selectedCbs));
  }, [selectedCbs, fullMpData]);

  const [mmChain, setmmChain] = useState<Chain | undefined>(undefined);
  try {
    window.ethereum.on('chainChanged', (chainId: string) => {
      const chainIdNum = parseInt(chainId, 16);
      // console.log('chainChanged', chainMap[chainIdNum]);
      setmmChain(chainMap[chainIdNum]);
    });
  } catch (e) {}

  usePWA();
  useAutoNightMode();
  useThemeWatcher();

  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <meta name="theme-color" content={themeObject[theme].primary} />
        <GlobalStyle />
        <HelmetProvider>
          <ConfigProvider locale={language === 'en' ? enUS : deDe}>
            <AppRouter
              spinning={spinning}
              connected={connected}
              marketFeed={marketFeed}
              marketplaceTableData={mpTableData}
              setSelectedProduct={setSelectedProduct}
              selectedProduct={selectedProduct}
              setProductDetails={setProductDetails}
              productDetails={productDetails}
              resetTrigger={resetTrigger}
              setResetTrigger={setResetTrigger}
              cexDexSelection={cexDexSelection}
              setCexDexSelection={setCexDexSelection}
              networkSelection={networkSelection}
              setNetworkSelection={setNetworkSelection}
              fullMarketplaceData={fullMpData}
              connectedUser={connectedUser}
              competitionTableData={competitionTableData}
              compAggDataList={compAggDataList}
              relativeGainsData={relativeGainsData}
              aggAllocs={aggAllocs}
              selectedCbs={selectedCbs}
              setSelectedCbs={setSelectedCbs}
              assetStrengths={assetStrengths}
              nextCompTs={nextCompTs}
            />
          </ConfigProvider>
        </HelmetProvider>
      </WagmiConfig>
      <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
        defaultChain={mmChain}
        explorerRecommendedWalletIds={[
          'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', // metamask
          // '163d2cf19babf05eb8962e9748f9ebe613ed52ebf9c8107c9a0f104bfcf161b3', // brave
          // '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0', // trustwallet
          // 'ef333840daf915aafdc4a004525502d6d49d77bd9c65e0642dbaefb3c2893bef', // imtoken
        ]}
        explorerExcludedWalletIds="ALL"
      />
    </>
  );
};

export default App;
