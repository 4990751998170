import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseChart } from '@app/components/common/charts/BaseChart';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { themeObject } from '@app/styles/themes/themeVariables';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { ACTUAL_DATA_EPOCH_MS } from '@app/lib/contracts';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { Simulate } from 'react-dom/test-utils';
import compositionEnd = Simulate.compositionEnd;
import { GenericCard } from '@app/components/vaults-dashboard/genericCard/GenericCard';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BASE_COLORS } from '@app/styles/themes/constants';
import { AggregatedData, CompetitionDataPoint } from '@app/lib/data';
const YEAR_MS = 52 * 7 * 24 * 60 * 60 * 1000;

interface IStrengthHistoryChartProps {
  data: CompetitionDataPoint[];
  spinning: boolean;
  title: string;
  valueName: string;
  unit: string;
  greenRed: boolean;
  chartType: 'line' | 'bar';
}

export const StrengthHistoryChart: React.FC<IStrengthHistoryChartProps> = (props: IStrengthHistoryChartProps) => {
  const theme = useAppSelector((state) => state.theme.theme);

  const option = {
    animationDuration: 700,
    tooltip: {
      formatter: function (params: any) {
        const cycle = params[0].axisValue;

        return `<b>${params[0].value.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })} ${props.unit}</b><br/>Round: ${cycle}`;
      },
      textStyle: {
        align: 'right', // Align text to the right
      },
      trigger: 'axis',
      axisPointer: {
        type: 'none',
      },
    },
    xAxis: {
      type: 'category',
      data: props.data.map((item) => item.cycle),
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      min: -10,
      max: 10,
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        formatter: function (value: number) {
          if (Math.abs(value) >= 100000) {
            return value / 1000000 + 'm';
          } else if (Math.abs(value) >= 1000) {
            return value / 1000 + 'k';
          }
          return value;
        },
        fontSize: 8,
      },
    },
    // grid: {
    //   containLabel: true,
    //   left: 65,
    //   bottom: 10,
    //   right: 30,
    // },
    series: [
      {
        name: props.valueName,
        data: props.data.map((item) => item.value),
        type: props.chartType,
        // smooth: true,
        symbol: 'none',
        // animation: true,
        // animationDuration: 1000,
        // animationDurationUpdate: 0,
      },
    ],

    visualMap: {
      show: false,
      min: -1.1 * props.data.reduce((max, p) => (Math.abs(p.value) > max ? Math.abs(p.value) : max), 0),
      max: 1.1 * props.data.reduce((max, p) => (Math.abs(p.value) > max ? Math.abs(p.value) : max), 0),
      inRange: {
        color: props.greenRed
          ? [themeObject[theme]['error'], themeObject[theme]['textMain'], themeObject[theme]['success']]
          : [themeObject[theme]['textMain']],
      },
    },
  };

  return (
    <BaseCard padding="0 0 0 0" bordered={true}>
      <BaseRow
        // gutter={[4, 4]}
        align="middle"
        style={{ justifyContent: 'space-between', paddingLeft: '1vh', paddingRight: '1vh', paddingTop: '1vh' }}
      >
        <BaseCol span={24}>
          <h1 style={{ fontWeight: 'bold' }}>{`${props.title}`}</h1>
        </BaseCol>
      </BaseRow>

      <BaseChart
        option={option}
        height="14rem"
        style={{ marginBottom: '-4vh', marginTop: '-4vh', paddingLeft: '2vh' }}
      />
    </BaseCard>
  );
};

export default StrengthHistoryChart;
