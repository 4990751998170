import React from 'react';
import * as S from '../Header.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { Web3Button } from '@web3modal/react';
import { ThemePicker } from '@app/components/header/components/settingsDropdown/settingsOverlay/ThemePicker/ThemePicker';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

interface MobileHeaderProps {
  toggleSider: () => void;
  isSiderOpened: boolean;
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({ toggleSider, isSiderOpened }) => {
  return (
    <BaseRow justify="space-between" align="middle">
      <BaseCol>
        <BaseRow align="middle">
          {/*<BaseCol>*/}
          {/*  <NotificationsDropdown />*/}
          {/*</BaseCol>*/}
          <S.BurgerCol>
            <S.MobileBurger onClick={toggleSider} isCross={isSiderOpened} />
          </S.BurgerCol>

          {/*<BaseCol>*/}
          {/*  <HeaderSearch />*/}
          {/*</BaseCol>*/}

          <BaseCol>
            {/*<SettingsDropdown />*/}
            <ThemePicker />
          </BaseCol>

          <BaseCol>
            {/*<ProfileDropdown />*/}
            <Web3Button />
          </BaseCol>

          {/*<BaseCol style={{ marginLeft: '2vh' }}>*/}
          {/*  <Button*/}
          {/*    size={'small'}*/}
          {/*    // type={'primary'}*/}
          {/*    href={*/}
          {/*      'https://firebasestorage.googleapis.com/v0/b/yiedl-production.appspot.com/o/dataset_2024_37.zip?alt=media&token=a2f8e221-fd34-4a07-89d6-dd133daa2a24'*/}
          {/*    }*/}
          {/*  >*/}
          {/*    <DownloadOutlined /> Download Crypto Dataset*/}
          {/*  </Button>*/}
          {/*</BaseCol>*/}
        </BaseRow>
      </BaseCol>
    </BaseRow>
  );
};
