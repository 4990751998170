import React, { useState } from 'react';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { ColumnsType } from 'antd/es/table';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { BASE_COLORS } from '@app/styles/themes/constants';
import { themeObject } from '@app/styles/themes/themeVariables';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { DashboardCard } from '@app/components/medical-dashboard/DashboardCard/DashboardCard';
import { useNavigate, useParams } from 'react-router-dom';
import * as S from './DashboardPages/DashboardPage.styles';
import * as ICS from './../components/vaults-dashboard/infoCard/InfoCard.styles';
import { activeUser } from '@app/lib/contracts';

import {
  Allocation,
  calculateProductAllocation,
  getLatestChallengeNumber,
  ProductAllocationActions,
  ProductData,
  ProductSharesPredictors,
  TOKEN_LOGOS,
  UPDATE_INTERVAL_MS,
} from '@app/lib/data';
import * as data from '@app/lib/data';
import { BaseCollapse } from '@app/components/common/BaseCollapse/BaseCollapse';
import { Collapse, InputNumber } from 'antd';
import CollapsePanel from 'antd/es/collapse/CollapsePanel';
import { BigNumber, ethers } from 'ethers';
import { DECIMALS } from '@app/lib/contracts';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { InfoCard } from '@app/components/vaults-dashboard/infoCard/InfoCard';
import { AumCard } from '@app/components/vaults-dashboard/aumCard/AumCard';
import { useResponsive } from '@app/hooks/useResponsive';
import { ArrowLeftOutlined } from '@ant-design/icons';
import ParticipantChart from '@app/pages/ParticipantChart';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseSlider } from '@app/components/common/BaseSlider/BaseSlider';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
type AlignType = 'left' | 'right' | 'center';

interface IParticipantViewProps {
  resetTrigger: boolean;
}

const SKIPPED_CHALLENGES = [41, 42];

const ParticipantView: React.FC<IParticipantViewProps> = (props) => {
  const { isTablet, isDesktop } = useResponsive();
  const { id } = useParams<{ id: string }>();
  const { compName } = useParams<{ compName: string }>();
  const theme = useAppSelector((state) => state.theme.theme);
  const [participantDetails, setParticipantDetails] = React.useState<data.CompetitionData>(data.EMPTY_COMPETITION_DATA);
  const [spinning, setSpinning] = React.useState<boolean>(true);
  const [inputValue, setInputValue] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allocation, setAllocation] = useState<Allocation[]>([]);
  const [latestChallengeNumber, setLatestChallengeNumber] = useState<number>(1);
  const navigate = useNavigate();

  function greenRedDefault(input: number) {
    return (
      <span
        style={{
          color: input > 0 ? themeObject[theme]['success'] : input < 0 ? BASE_COLORS.red : 'default',
        }}
      >
        {input > 0 ? '+' + input.toFixed(2) : input.toFixed(2)}
        {}
      </span>
    );
  }

  const participantAllocationColumns: ColumnsType<Allocation> = [
    {
      title: 'Asset',
      dataIndex: 'asset',
      key: 'asset',
      sorter: (a, b) => a.asset.localeCompare(b.asset),
      render: (asset: string) => (
        <>
          <img
            alt={asset}
            src={TOKEN_LOGOS[asset]}
            style={{
              height: '1rem',
              display: 'inline-block',
              borderRadius: '50%',
              verticalAlign: 'middle',
              marginRight: '0.5rem',
              lineHeight: '1rem',
            }}
          />
          <span
            style={{
              display: 'inline-block',
              verticalAlign: 'middle',
              lineHeight: '1rem',
            }}
          >
            {asset}
          </span>
        </>
      ),
    },
    {
      title: 'Side',
      dataIndex: 'percentage',
      key: 'percentage',
      render: (percentage) => (percentage > 0 ? 'Long' : 'Short'),
      sorter: (a, b) => (a.percentage > 0 ? 'Long' : 'Short').localeCompare(b.percentage > 0 ? 'Long' : 'Short'),
    },
    {
      title: 'Allocation (%)',
      dataIndex: 'percentage',
      key: 'percentage',
      render: (percentage: number) => Math.abs(percentage).toFixed(2),
      sorter: (a, b) => Math.abs(a.percentage) - Math.abs(b.percentage),
    },
    {
      title: 'Gain (%)',
      dataIndex: 'gain',
      key: 'gain',
      render: (gain: number) => greenRedDefault(gain),
      sorter: (a, b) => a.gain - b.gain,
    },
  ];

  const onInputChange = (valueIn: number | null) => {
    valueIn = valueIn ? Number(valueIn.toFixed(0)) : 1;
    if (SKIPPED_CHALLENGES.includes(valueIn)) {
      if (valueIn == 41) {
        valueIn = 43;
      } else {
        valueIn = 40;
      }
    }
    setInputValue(valueIn);
  };

  const customHistAllocIndex = (value: number) => {
    if (value <= 41) {
      return value - 1;
    }
    return value - 3;
  };

  const formatter = (value: number | undefined) => {
    if (!value) return '';
    const numberValue = Number(value);
    return `${numberValue.toLocaleString()}`;
  };

  async function fetchParticipantDetails(): Promise<number> {
    const participantData = await data.getParticipantData(compName, id);

    setParticipantDetails(participantData as data.CompetitionData);
    return (participantData as data.CompetitionData).historicalRewards.length + 2;
  }

  // reset the input value to 0 and product allocation actions to empty on unmount
  React.useEffect(() => {
    return () => {
      setInputValue(latestChallengeNumber);
    };
  }, [props.resetTrigger]);

  // fetch product details refresh every 5 seconds, asynchonously
  React.useEffect(() => {
    (async () => {
      const latestChallenge = await fetchParticipantDetails();
      setLatestChallengeNumber(latestChallenge);
      setInputValue(latestChallenge);
      // force sleep for 1 second
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setSpinning(false);
    })();
    const intervalId = setInterval(async () => await fetchParticipantDetails(), UPDATE_INTERVAL_MS);
    // eslint-disable-next-line
    return () => clearInterval(intervalId);
  }, [props.resetTrigger]);

  return (
    <>
      <PageTitle>{`${(compName as string).toUpperCase()}: ${id?.slice(0, 5)}...${id?.slice(-3)}`}</PageTitle>
      <BaseRow>
        <BaseCol span={24} style={{ marginBottom: '2vh', marginLeft: '0vh' }}>
          <BaseButton type="link" icon={<ArrowLeftOutlined />} onClick={() => navigate('/competition')}>
            Back to Yiedl Competition
          </BaseButton>
        </BaseCol>
        <BaseRow gutter={[30, 30]}>
          <BaseCol span={24}>
            <BaseSpin spinning={spinning}>
              <InfoCard
                title={`${participantDetails.competition.toUpperCase()} | ${participantDetails.address}`}
                description={``}
              />
            </BaseSpin>
          </BaseCol>

          <BaseCol span={24}>
            {/*<h1 style={{ fontWeight: 'bold' }}>Competition KPIs</h1>*/}
            <BaseRow gutter={[30, 30]}>
              <BaseCol xs={12} md={12} order={(isTablet && 2) || 0}>
                <AumCard
                  title={'Relative Gain'}
                  value={participantDetails.latestRelativeGain}
                  spinning={spinning}
                  unit={'%'}
                />
              </BaseCol>
              <BaseCol xs={12} md={12} order={(isTablet && 3) || 0}>
                <AumCard
                  title={'Staked'}
                  value={participantDetails.currentStake}
                  spinning={spinning}
                  unit={'$YIEDL'}
                  greenRed={false}
                />
              </BaseCol>
              <BaseCol xs={12} md={12} order={(isTablet && 4) || 0}>
                <AumCard
                  title={'R/B Ratio'}
                  value={participantDetails.rewardBurnRatio}
                  spinning={spinning}
                  unit={''}
                  greenRed={false}
                  isRbRatio={true}
                />
              </BaseCol>
              <BaseCol xs={12} md={12} order={(isTablet && 5) || 0}>
                <AumCard
                  title={'AI Stage'}
                  value={participantDetails.aiAge}
                  spinning={spinning}
                  unit={''}
                  greenRed={false}
                />
              </BaseCol>
            </BaseRow>
          </BaseCol>

          <BaseCol id="big-chart" span={24}>
            <ParticipantChart
              spinning={spinning}
              title={'Historical Relative Gain'}
              valueName={'Relative Gain'}
              unit={'%'}
              data={participantDetails.historicalRelativeGains}
            />
          </BaseCol>
          <BaseCol id="big-chart" span={24}>
            <ParticipantChart
              spinning={spinning}
              title={'Historical Rewards'}
              valueName={'Rewards'}
              unit={'$YIEDL'}
              data={participantDetails.historicalRewards}
            />
          </BaseCol>

          {/*<References />*/}
          <BaseCol span={24}>
            {/*<h1 style={{ fontWeight: 'bold' }}>{`Historical Allocation`}</h1>*/}
            <BaseCard padding="1 1 0.875rem" title={`Historical Allocation`} loading={spinning}>
              <BaseRow gutter={[30, 30]}>
                <BaseCol span={24}>
                  <p>Show allocation at selected round number.</p>
                </BaseCol>
              </BaseRow>
              <BaseRow justify={'space-evenly'} align={'middle'} gutter={[12, 12]}>
                <BaseCol span={12}>
                  <InputNumber
                    style={{ minWidth: '80%' }}
                    onChange={onInputChange}
                    value={inputValue}
                    step={1}
                    min={1}
                    max={latestChallengeNumber}
                    prefix={'Round: '}
                    onStep={onInputChange}
                  />
                </BaseCol>
                <BaseCol span={12}>
                  <BaseSlider
                    // style={{ minWidth: 180 }}
                    onChange={onInputChange}
                    value={inputValue}
                    step={1}
                    min={1}
                    max={latestChallengeNumber}
                    // tooltip={{ open: false }}
                  />
                </BaseCol>
              </BaseRow>
              <BaseRow gutter={[10, 10]} style={{ marginTop: '1vh' }}>
                {' '}
                <BaseCol span={24}>
                  <p style={{ textAlign: 'right' }}>
                    Total Gain{' '}
                    {greenRedDefault(
                      participantDetails.historicalAllocations[customHistAllocIndex(inputValue)]
                        ? participantDetails.historicalAllocations[customHistAllocIndex(inputValue)].reduce(
                            (acc, cur) => acc + cur.gain,
                            0,
                          )
                        : 0,
                    )}
                    {' %'}
                  </p>
                </BaseCol>
                <BaseCol span={24}>
                  {(participantDetails.historicalAllocations[customHistAllocIndex(inputValue)] || []).length > 0 ? (
                    <BaseTable
                      columns={participantAllocationColumns}
                      dataSource={participantDetails.historicalAllocations[customHistAllocIndex(inputValue)] || []}
                      loading={isLoading}
                      pagination={false}
                      size={'small'}
                      bordered={true}
                    />
                  ) : (
                    <BaseTable
                      columns={participantAllocationColumns}
                      dataSource={[
                        {
                          asset: 'USDT',
                          gain: 0,
                          percentage: 100,
                        } as Allocation,
                      ]}
                      loading={isLoading}
                      pagination={false}
                      size={'small'}
                      bordered={true}
                    />
                  )}
                </BaseCol>
              </BaseRow>
            </BaseCard>
          </BaseCol>
        </BaseRow>
      </BaseRow>
    </>
  );
};

export default ParticipantView;
