import React from 'react';
import { useTranslation } from 'react-i18next';
import { useResponsive } from '@app/hooks/useResponsive';
import { StatisticColor } from '@app/constants/config/statistics';
import * as S from '../statisticsCards/statisticsCard/StatisticsCard/StatisticsCard.styles';
import * as I from '../statisticsCards/statisticsCard/StatisticsInfo/StatisticsInfo.styles';
import * as P from '../statisticsCards/statisticsCard/StatisticsProgress/StatisticsProgress.styles';
import { themeObject } from '@app/styles/themes/themeVariables';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import Marquee from 'react-fast-marquee';
import { MarketResponseObject } from '@dydxprotocol/v3-client';
import { Space } from 'antd';
import { MarketFeed } from '@app/lib/contracts';
import { TOKEN_LOGOS } from '@app/lib/data';

function getPctChange(price: string, change: string, asNum: boolean) {
  const initialPrice = Number(price) - Number(change);
  const pctChange = Number(change) / initialPrice;
  return asNum
    ? pctChange
    : pctChange.toLocaleString('en-US', {
        style: 'percent',
        maximumSignificantDigits: 2,
      });
}

interface TickerProps {
  marketFeed: Array<any>;
}

export const Ticker: React.FC<TickerProps> = (props) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const { isTablet: isTabletOrHigher } = useResponsive();
  const { t } = useTranslation();

  const [markets, setMarkets] = React.useState<Array<any>>([]);

  return (
    <BaseRow>
      <Marquee
        onCycleComplete={() => {
          setMarkets(props.marketFeed);
        }}
        play={markets.length > 0}
      >
        {markets.length > 0 ? (
          markets.map((mkt: MarketFeed) => {
            // const numStr = mkt.price
            return (
              <>
                <img
                  alt={mkt.symbol}
                  src={TOKEN_LOGOS[mkt.symbol]}
                  style={{
                    height: '1rem',
                    display: 'inline-block',
                    borderRadius: '50%',
                    marginRight: '0.2rem',
                    lineHeight: 'inherit',
                    verticalAlign: 'middle',
                  }}
                />
                <text
                  style={{
                    color: themeObject[theme].textMain,
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    verticalAlign: 'middle',
                  }}
                >
                  {mkt.symbol}{' '}
                </text>
                <text style={{ color: themeObject[theme].textMain, fontSize: 'small', verticalAlign: 'middle' }}>
                  {mkt.price}{' '}
                </text>
                <text
                  style={{
                    fontSize: 'x-small',
                    color:
                      // (getPctChange(mkt.indexPrice, mkt.priceChange24H, true) as number) > 0
                      //   ? themeObject[theme].success
                      //   : (getPctChange(mkt.indexPrice, mkt.priceChange24H, true) as number) < 0
                      //   ? themeObject[theme].error
                      //   : themeObject[theme].textMain,
                      themeObject[theme].textMain,
                  }}
                >
                  {/*  {*/}
                  {/*    (((getPctChange(mkt.indexPrice, mkt.priceChange24H, true) as number) > 0 ? '+' : '') +*/}
                  {/*      getPctChange(mkt.indexPrice, mkt.priceChange24H, false)) as string*/}
                  {/*  }*/}
                  &emsp; &emsp;
                </text>
              </>
            );
          })
        ) : (
          <BaseSpin size="small" spinning={true} />
        )}
      </Marquee>
    </BaseRow>
  );
};
