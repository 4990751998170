import React from 'react';
import * as S from '../Header.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { Web3Button } from '@web3modal/react';
import { ThemePicker } from '@app/components/header/components/settingsDropdown/settingsOverlay/ThemePicker/ThemePicker';
import { DownloadOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { Button } from 'antd';
import { themeObject } from '@app/styles/themes/themeVariables';
import { useAppSelector } from '@app/hooks/reduxHooks';

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({ isTwoColumnsLayout }) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const leftSide = isTwoColumnsLayout ? (
    <S.SearchColumn xl={16} xxl={17}>
      <BaseRow justify="space-between">
        <BaseCol xl={15} xxl={12}>
          {/*<HeaderSearch />*/}
          <BaseCol>
            {/*<BaseRow gutter={[{ xxl: 5 }, { xxl: 5 }]}>*/}
            {/*  <BaseCol style={{ marginRight: '2vh' }}>*/}
            {/*    <span*/}
            {/*      className="pulsing-button"*/}
            {/*      style={*/}
            {/*        {*/}
            {/*          display: 'inline-block',*/}
            {/*          // '--pulse-color': themeObject[theme]['success'],*/}
            {/*          '--pulse-color': themeObject[theme]['primary'],*/}
            {/*        } as React.CSSProperties*/}
            {/*      }*/}
            {/*    >*/}
            {/*      <Button*/}
            {/*        size={'small'}*/}
            {/*        // type={'primary'}*/}
            {/*        href={*/}
            {/*          'https://firebasestorage.googleapis.com/v0/b/yiedl-production.appspot.com/o/dataset_2024_37.zip?alt=media&token=a2f8e221-fd34-4a07-89d6-dd133daa2a24'*/}
            {/*        }*/}
            {/*      >*/}
            {/*        <DownloadOutlined /> Download Crypto Dataset*/}
            {/*      </Button>*/}
            {/*    </span>*/}
            {/*  </BaseCol>*/}
            {/*</BaseRow>*/}
          </BaseCol>
        </BaseCol>
        {/*<BaseCol>*/}
        {/*  <S.GHButton />*/}
        {/*</BaseCol>*/}
      </BaseRow>
    </S.SearchColumn>
  ) : (
    <>
      <BaseCol lg={10} xxl={8}>
        {/*<HeaderSearch />*/}
        <BaseCol>
          {/*<BaseRow gutter={[{ xxl: 5 }, { xxl: 5 }]}>*/}
          {/*  <BaseCol style={{ marginRight: '2vh' }}>*/}
          {/*    <span*/}
          {/*      className="pulsing-button"*/}
          {/*      style={*/}
          {/*        {*/}
          {/*          display: 'inline-block',*/}
          {/*          // '--pulse-color': themeObject[theme]['success'],*/}
          {/*          '--pulse-color': themeObject[theme]['primary'],*/}
          {/*        } as React.CSSProperties*/}
          {/*      }*/}
          {/*    >*/}
          {/*      <Button*/}
          {/*        size={'small'}*/}
          {/*        // type={'primary'}*/}
          {/*        href={*/}
          {/*          'https://firebasestorage.googleapis.com/v0/b/yiedl-production.appspot.com/o/dataset_2024_37.zip?alt=media&token=a2f8e221-fd34-4a07-89d6-dd133daa2a24'*/}
          {/*        }*/}
          {/*      >*/}
          {/*        <DownloadOutlined /> Download Crypto Dataset*/}
          {/*      </Button>*/}
          {/*    </span>*/}
          {/*  </BaseCol>*/}
          {/*</BaseRow>*/}
        </BaseCol>
      </BaseCol>
      {/*<BaseCol>*/}
      {/*  <S.GHButton />*/}
      {/*</BaseCol>*/}
    </>
  );

  return (
    <BaseRow justify="space-between" align="middle">
      {leftSide}

      <S.ProfileColumn xl={8} xxl={7} $isTwoColumnsLayout={isTwoColumnsLayout}>
        <BaseRow align="middle" justify="end" gutter={[5, 5]}>
          <BaseCol>
            <BaseRow gutter={[{ xxl: 5 }, { xxl: 5 }]}>
              {/*<BaseCol>*/}
              {/*  <HeaderFullscreen />*/}
              {/*</BaseCol>*/}

              {/*<BaseCol>*/}
              {/*  <NotificationsDropdown />*/}
              {/*</BaseCol>*/}

              <BaseCol style={{ marginRight: '2vh' }}>
                {/*<SettingsDropdown />*/}
                <ThemePicker />
              </BaseCol>
            </BaseRow>
          </BaseCol>

          <BaseCol>
            {/*<ProfileDropdown />*/}
            <Web3Button />
          </BaseCol>
        </BaseRow>
      </S.ProfileColumn>
    </BaseRow>
  );
};
